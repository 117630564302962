<template>
  <div class="container" :class="bg==='none'? '': 'bg'">
    <div class="logo_wrapper">
      <img src="../../static/img/home/logo1.png" class="logo">
    </div>
    <div class="navs">
      <div v-for="(item, index) in tabs" :key="index"
        :class="['nav-item', {'is-active':current==index}]"
        @click="handleTab(item, index)">
        {{ item.name }}
        <div v-if="item.child" class="navs_dropdown">
          <div class="dropdown_item" v-for="(obj, i) in item.child" :key="i"
            @click.stop="handleItem(obj, index)">
            <div class="line"></div>
            <div class="wrapline">
              <span>{{ obj.title }}</span>
              <div class="navs_icon">
                <!-- <img src="../../static/img/home/gov.png" alt=""> -->
                <img class="img1"
                  :src="require('../../static/img/home/' + obj.imgurl + '.png')"
                  alt="">
                <img class="img2"
                  :src="require('../../static/img/home/h' + obj.imgurl + '.png')"
                  alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    bg: String
  },
  data () {
    return {
      flag: '产品中心',
      tabs: [
        {
          name: '首页',
          url: 'home',
          path: '/'
        },
        {
          name: '产品中心',
          url: 'product-center',
          path: '',
          child: [
            {
              title: '软件中心',
              imgurl: 'icon1',
              path: 'Softwore'
            },
            {
              title: '硬件中心',
              imgurl: 'icon2',
              path: 'Hardwore'
            }
          ]
        },
        {
          name: '解决方案',
          url: 'solution',
          path: '',
          child: [
            {
              title: '政府行业信息化',
              imgurl: 'icon3',
              path: 'SmartSecuriity',
              index: 1
            },
            {
              title: '人脸识别',
              imgurl: 'icon4',
              path: 'SmartSecuriity',
              index: 2
            },
            {
              title: '智慧校园',
              imgurl: 'icon5',
              path: 'SmartSecuriity',
              index: 3
            },
            {
              title: '智慧宿舍',
              imgurl: 'icon6',
              path: 'SmartSecuriity',
              index: 4
            },
            {
              title: '智慧社区',
              imgurl: 'icon7',
              path: 'SmartSecuriity',
              index: 5
            },
            {
              title: '智慧安防',
              imgurl: 'icon8',
              path: 'SmartSecuriity',
              index: 6
            }
          ]
        },
        {
          name: '案例展示',
          url: 'case-show',
          path: '/caseshow'
        },
        {
          name: '公司动态',
          url: 'news',
          path: '/news'
        },
        {
          name: '关于睿腾',
          url: 'about',
          path: '/about'
        }
      ],
      current: 0
    }
  },
  mounted () {
    if (this.$route.name === 'Home') {
      this.current = 0
    } else {
      this.current = localStorage.getItem('index')
    }
  },
  methods: {
    handleItem (obj, index) {
      localStorage.setItem('index', index)
      this.current = index
      if (obj.index) {
        this.$router.push({ name: obj.path, params: { id: obj.index } })
      } else {
        this.$router.push({ name: obj.path })
      }
    },
    handleTab (item, index) {
      localStorage.setItem('index', index)
      this.current = index
      if (item.path === '' || this.$route.path === item.path) {
        return
      }
      this.$router.push(item.path)
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  min-width: 1200px;
  box-sizing: border-box;
  width: 100%;
  height: 75px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bg {
  background: #353947;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
}
.logo_wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 20px;
  .logo {
    width: 143px;
    height: 28.56px;
  }
}

.navs {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  height: 100%;
}
.nav-item {
  cursor: pointer;
  position: relative;
  width: 130px;
  height: 100%;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  display: flex;
  // justify-content: center;
  align-items: center;
  // padding-top: 20px;
  // padding-bottom: 5px;
  color: #ffffff;
  // opacity: 0.87;

  .wrapline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    width: 139px;
    border-bottom: 1px solid #333333;
    border-color: rgba(51, 51, 51, 0.1);
    span {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      // line-height: 14px;
      color: #333333;
      // letter-spacing: 60px;
      opacity: 0.6;
    }
  }
  &:hover::after,
  &.is-active::after {
    content: "";
    display: block;
    width: 24px;
    height: 3px;
    background: #4285f4;
    position: absolute;
    bottom: 16px;
  }
  &:hover span {
    opacity: 1;
  }
  &:hover .navs_dropdown {
    display: block;
  }
}
.navs_dropdown {
  z-index: 999;
  position: absolute;
  width: 180px;
  left: 0;
  top: 67px;
  display: none;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
  opacity: 1;
  // border-radius: 4px;
  overflow: hidden;
  opacity: 1;
  .dropdown_item {
    position: relative;
    width: 100%;
    height: 61px;
    line-height: 60px;
    text-align: center;
    // border-bottom: 1px solid #eee;
    // border--bottom-width: 139px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 20px 21px 21px 20px;
    align-items: center;
    color: #353947;
    &:last-child {
      border: none;
    }
    &:hover span,
    &.is-active span {
      color: #4285f4;
    }
    &::after {
      width: 139px;
      height: 1px;
      border: 1px solid #333333;
      opacity: 0.1;
    }
    span {
      // padding-left: 17px;
    }
    &:hover .line {
      background: #4285f4;
    }
    &:hover .img1,
    &.is-active .img1 {
      // background: #4285f4;
      display: none;
    }

    .line {
      position: absolute;
      left: 0;
      top: 20px;
      width: 3px;
      height: 20px;
    }
    .navs_icon {
      width: 15px;
      height: 15px;
      opacity: 1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .img2 {
        display: none;
      }
      // img{
      // width: 13.15px;
      // height: 14.43px;
      // }
    }
    &:hover .img2 {
      display: block;
    }
  }
}
</style>
