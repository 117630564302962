<template>
  <div class="container">
    <div class="footer-top">
      <div class="contact-us img-info">
        <div class="title">联系我们</div>
        <div class="info">河南睿腾信息技术有限公司</div>
        <div class="info">客户服务热线：0371-69136260</div>
        <div class="info">客户服务时间：8:30-17:30</div>
        <div class="info">企业邮箱：www.hnrtxxwork@dingtalk.com</div>
        <div class="info">公司地址：郑州市恒丰科创中心1号楼501</div>
      </div>
      <div class="info-line"></div>
      <div class="links img-info">
        <div class="title">友情链接</div>
        <a href="https://www.henan.gov.cn/" target="_blank"
          class="info">河南省政府</a>
        <a href="http://scjg.henan.gov.cn/" target="_blank"
          class="info">河南工商管理局</a>
        <a href="http://222.143.21.205:8081/" target="_blank"
          class="info">河南省政府采购商城</a>
        <a href="https://www.sensetime.com/" target="_blank"
          class="info">商汤集团</a>
      </div>
      <div class="info-line"></div>
      <div class="follow-us img-info">
        <div class="title">关注我们</div>
        <img src="../../static//img/home/rect2.png" alt="">
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-content">
        <!-- <span><router-link :to="{name: 'companyInfo'}">©2021 河南睿腾信息技术有限公司</router-link></span> -->
        <span>©2021 河南睿腾信息技术有限公司</span>
        <span>备案号：<a href="https://beian.miit.gov.cn"
            target="_blank">豫B2-20190210-2</a></span>
        <span>
          <a target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010902002082"
            style="display:inline-block;text-decoration:none;">
            <img src="@/assets/record.png" />
            <span style="color:#fff;">
              豫公网安备 41010902002082号</span>
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style scoped lang="scss">
.container {
  min-width: 1200px;
  // width: 1920px;
  height: 1.442708rem;
  background: #353947;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.footer-top {
  width: 100%;
  height: 1.026042rem;
  display: flex;
  justify-content: center;
  padding: 0.208333rem 0 0.15625rem 0;
  .info-line {
    width: 0px;
    height: 0.53125rem;
    border: 1px solid #ffffff;
    opacity: 0.1;
  }
}
a {
  display: block;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #4285f4;
    opacity: 1;
  }
}
.footer-bottom {
  height: 0.416667rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  .footer-content {
    font-size: 0.072917rem;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 0.114583rem;
    color: #ffffff;
    opacity: 0.6;
    span {
      padding-right: 25px;
      a {
        color: #fff;
        display: inline;
        &:hover {
          color: #4285f4;
          opacity: 1;
        }
      }
    }
    span:nth-child(3) {
      position: relative;
      padding-left: 0.1042rem;
      img {
        position: absolute;
        top: -0.0104rem;
        left: 0px;
      }
      span {
        font-size: 0.072917rem;
        font-weight: 400;
        line-height: 0.114583rem;
        color: #ffffff;
      }
    }
  }
}
.img-info {
  padding: 0 0.651042rem;
  img {
    width: 0.390625rem;
    height: 0.385417rem;
  }
}
.title {
  height: 0.114583rem;
  font-size: 0.104167rem;
  font-weight: 400;
  line-height: 0.114583rem;
  color: #ffffff;
  opacity: 0.87;
  margin-bottom: 0.098958rem;
}
.info {
  font-size: 0.083333rem;
  line-height: 0.114583rem;
  color: #ffffff;
  opacity: 0.6;
  padding: 0.5px 0;
}
</style>