<template>
  <div>
    <Header
      v-if="this.$route.path !== '/' && this.$route.name !== 'companyInfo'" />
    <router-view></router-view>
    <Footer v-if="this.$route.name !== 'companyInfo'" />
  </div>
</template>
<script>
import Header from "../components/Header";
import Footer from '../components/Footer'
export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data () {
    return {

    }
  },
}
</script>
<style scoped lang="scss">
</style>