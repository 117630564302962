import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
   }
const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home')
      },
      {
        path: '/softwore',
        name: 'Softwore',
        component: () => import('../views/product-center/softwore')
      },
      {
        path: '/hardwore',
        name: 'Hardwore',
        component: () => import('../views/product-center/hardwore')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/about')
      },
      {
        path: '/caseshow',
        name: 'Caseshow',
        component: () => import('../views/case-show')
      },
      {
        path: '/smart-securiity/:id',
        name: 'SmartSecuriity',
        component: () => import('../views/solution/smart-security')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../views/news/news')
      },
      {
        path: '/company-info',
        name: 'companyInfo',
        component: () => import('../views/company-info')
      }
    ]
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
